<template>
    <fast-table
        v-show="dataLoaded"
        :sections="sections"
        :headers="headers"
        :data="tableData"
        @click="currentPayItem = $event"
    >

            <template v-slot:table-actions>
                <button type="button" class="btn btn-primary btn-collapse" @click.prevent="checkPayrollPayItems()">
                    <i class="far fa-fw fa-question-circle"></i> <span class="btn-text">Check Payroll</span>
                </button>
            </template>

    </fast-table>
</template>

<script>
import FastTable from '@/components/FastTable'

export default {
    components: {FastTable, },
    props: ['client', 'itemTypeSlug', 'itemTypeDescription'],
    computed: {
        payItemsActive() {
            return this.pay_items.filter(t => t.is_active)
        },
        payItemsInactive() {
            return this.pay_items.filter(t => !t.is_active)
        },
        tableData() {
            const tableData = []

            const subsets = [
                this.payItemsActive,
                this.payItemsInactive,
            ]

            subsets.forEach(pay_items => {
                const sectionData = []

                pay_items.forEach((o, idx) => {
                    const row = {
                        id: idx,
                        isActive: this.currentPayItem && o.id == this.currentPayItem.id,
                        object: o,
                        cells: [
                            o.description,
                            o.code,
                            o.system_pay_item,
                            o.is_active,
                        ],
                    }

                    sectionData.push(row)
                })
                tableData.push(sectionData)
            })

            return tableData
        },
    },
    data() {
        return {
            pay_items: [],
            dataLoaded: false,
            currentPayItem: null,

            headers: [
                {label: 'Description', classes: '', isSearchable: true},
                {label: 'Code', classes: '', isSearchable: true},
                {label: 'System Pay Item', classes: '', isSearchable: true, isFilterable: true},
                {label: 'Active', classes: '', type: "checkmark", isSearchable: false},
            ],
            sections: [
                {title: 'Active', defaultIsClosed: false, id: 1},
                {title: 'Inactive', defaultIsClosed: true, id: 2},
            ],
        }
    },
    mounted() {
        this.$store.dispatch('SET_PAGE_TITLE', `Client ${this.client.client_code} - ${this.client.name} / Pay Items / ${this.itemTypeDescription}`)
        this.getData()
    },
    methods: {
        getData() {
            this.dataLoaded = false
            this.$store.dispatch('START_LOADING')
            this.$api.get(`/admin/clients/${this.client.id}/pay-items/${this.itemTypeSlug}`).then((resp) => {
                this.pay_items = resp
                this.dataLoaded = true
                this.$store.dispatch('STOP_LOADING')
            }).catch((errors) => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__[0])
            })
        },
        checkPayrollPayItems() {
            this.$store.dispatch('START_LOADING')
            this.$api.get(`/admin/clients/${this.client.id}/pay-items/check-payroll`).then((resp) => {
                this.$store.dispatch('STOP_LOADING')
                if (resp['issues_found'].length == 0) {
                    this.$bus.showInfo('No problems detected.')
                } else {
                    resp['issues_found'].forEach(item => {
                        this.$bus.showError(item)
                    })
                }
            }).catch((errors) => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__[0])
            })
        }
    },
}

</script>
