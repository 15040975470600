import 'core-js/stable'
import { createApp, nextTick } from 'vue'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'
import {PMDateFilters, PMBus, PMAPI, PMPermissions, PMConstants, IDHasher} from '@/plugins'
import PortalVue from 'portal-vue'
import PrimeVue from 'primevue/config'
// import VueTour from 'vue-tour'

import MoreInfo from '@/components/MoreInfo'
import CollapseToggleButton from '@/components/CollapseToggleButton'
import AlertBox from '@/components/AlertBox'
import FormInput from '@/components/FormInput'
import FormErrors from '@/components/FormErrors'
import PageSearch from '@/components/PageSearch'

import 'jquery'
import 'popper.js'
import 'bootstrap/dist/js/bootstrap.js'
import 'muicss/dist/js/mui.min.js'
import 'toastr/toastr.scss'
import 'select2/dist/css/select2.min.css'
// import 'vue-tour/dist/vue-tour.css'

import '@/polyfills/endsWith'

import '@/assets/fonts/roboto/roboto.css'
import '@/assets/font-awesome/css/all.css'

import '@/assets/scss/style.scss'
import '@/assets/scss/local.scss'

import * as Sentry from "@sentry/vue";

const app = createApp(App)

if (import.meta.env.VITE_SENTRY_DSN) {
    const tracesSampleRate = import.meta.env.VITE_SENTRY_TRACE_SAMPLE_RATE || 0
    const tracePropagationTarget = import.meta.env.VITE_API_URL || 'localhost'
    Sentry.init({
        app,
        dsn: import.meta.env.VITE_SENTRY_DSN,
        environment: import.meta.env.VITE_DELTA_IS_PRODUCTION ? 'production' : import.meta.env.VITE_DELTA_ENV_CODE,
        ignoreErrors: [
            "Rendering cancelled",
        ],
        integrations: [
        //   Sentry.browserTracingIntegration({ router }),
        ],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for tracing.
        // We recommend adjusting this value in production
        // tracesSampleRate: (tracesSampleRate),

        // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
        // tracePropagationTargets: [tracePropagationTarget],
      });
}

app.config.globalProperties.$nextTick = nextTick
app.use(router)
app.use(store)

app.use(PMDateFilters)
app.use(PMAPI)
app.use(PMBus)
app.use(PMPermissions)
app.use(PMConstants)
app.use(PortalVue)
app.use(IDHasher)
// app.use(VuePortal)
app.use(PrimeVue)

app.component('MoreInfo', MoreInfo)
app.component('CollapseToggleButton', CollapseToggleButton)
app.component('AlertBox', AlertBox)
app.component('FormInput', FormInput)
app.component('FormErrors', FormErrors)
app.component('PageSearch', PageSearch)

app.mount('#app')

// Mixpanel
import mixpanel from 'mixpanel-browser'
if (import.meta.env.VITE_MIXPANEL_ENABLED && import.meta.env.VITE_MIXPANEL_PROJECT_TOKEN) {
    try {
        mixpanel.init(import.meta.env.VITE_MIXPANEL_PROJECT_TOKEN, {debug: false})
    }
    catch (ex) {
        Sentry.captureException(ex)
    }
}
