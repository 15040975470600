<script>
import FilterWorker from './filter.worker.js?worker'

export default {
    data() {
        return {
            filteredDataIds: null,
            filterWorker: null,
        }
    },
    watch: {
        filters() {
            this.performFiltering()
        },
        data() {
            this.populateWorkerData()
        },
    },
    created() {
        this.filterWorker = new FilterWorker()
        this.filterWorker.addEventListener('message', (m) => {
            if (m.data.action == 'filter') {
                this.filteredDataIds = m.data.result
            }
        })
    },
    unmounted() {
        if (this.filterWorker) {
            this.filterWorker.terminate()
            this.filterWorker = null
        }
    },
    computed: {
        filteredData() {
            if (this.filteredDataIds) {
                const result = []

                this.filteredDataIds.forEach((sectionIds, sectionIdx) => {
                    sectionIds = new Set(sectionIds)
                    result[sectionIdx] = this.data[sectionIdx].filter(r => sectionIds.has(r.id))
                })
                return result
            }
            return this.data
        },
    },
    methods: {
        populateWorkerData() {
            // turn Vue 3 Proxy object into raw object before sending to worker via postMessage
            const headers = this.headers ? JSON.parse(JSON.stringify(this.headers)) : [] // May contain methods
            const sections = this.sections ? JSON.parse(JSON.stringify(this.data)) : []
            const data = this.data ? JSON.parse(JSON.stringify(this.data)) : {}
            const managers = this.managers ? JSON.parse(JSON.stringify(this.managers)) : {}
            this.filterWorker.postMessage({
                action: 'create',
                headers: headers,
                sections: sections,
                data: data,
                managers: managers,
            })
        },
        performFiltering() {
            if (this.filters && this.filters.length) {
                const filters = JSON.parse(JSON.stringify(this.filters))
                this.filterWorker.postMessage({
                    action: 'filter',
                    filters: filters,
                })
            }
            else {
                this.filteredDataIds = null
            }
        },
    },
}
</script>
