<template>
<div>
    <div class="nav-tabs-container pt-1" id="tabsPageNav">
        <div class="nav-tabs-scroll-helper">
            <ul class="nav nav-tabs">

                <li class="nav-item">
                    <a class="nav-link" :class="{'active': mode == 'all'}" href id="tabLinkPositions1" role="tab" aria-controls="tabContentPositions1" :aria-selected="mode == 'all'" @click.prevent="mode = 'all'; $bus.$emit('resize')">
                        All Positions ({{ allPositionCount }})
                    </a>
                </li>

                <li class="nav-item">
                    <a class="nav-link" :class="{'active': mode == 'selected'}" href id="tabLinkPositions2" role="tab" aria-controls="tabContentPositions1" :aria-selected="mode == 'selected'" @click.prevent="mode = 'selected'; $bus.$emit('resize')">
                        Selected ({{ selectedPositionCount }})
                    </a>
                </li>

            </ul>
        </div>
    </div>

    <div class="flex-body flex-container tab-content">
        <div class="flex-body flex-container tab-pane" :class="{'show active': mode == 'all'}" id="tabContentPositions1" role="tabpanel" aria-labelledby="tabLinkPositions1">
            <fast-table
                ref="allPositionsTable"
                :sections="positionSections"
                :headers="positionHeaders"
                :data="positionTableData"
                @click="onPositionClick"
                @select="onPositionSelect"
                @filter-edit-open="$emit('filter-edit-open')"
                @filter-edit-close="$emit('filter-edit-close')"
                :use-overlay-filter-editor="true"
                :has-row-select="true"
                :show-search="true"
                :show-filter="true"
                :show-more-actions="false"
            />
        </div>

        <div class="flex-body flex-container tab-pane" :class="{'show active': mode == 'selected'}" id="tabContentPositions2" role="tabpanel" aria-labelledby="tabLinkPositions2">
            <fast-table
                ref="selectedPositionsTable"
                :sections="positionSections"
                :headers="positionHeaders"
                :data="selectedPositionTableData"
                @click="onPositionClick"
                @select="onPositionSelect"
                @filter-edit-open="$emit('filter-edit-open')"
                @filter-edit-close="$emit('filter-edit-close')"
                :use-overlay-filter-editor="true"
                :hasRowSelect="true"
                :showSearch="true"
                :showFilter="true"
                :showMoreActions="false"
            />
        </div>

    </div>
</div>
</template>

<script>
import FastTable from '@/components/FastTable'

export default {
    props: ['client', 'clientPositions', 'modelValue', 'hideCompanyFilter', ],
    emits: ['update:modelValue', 'filter-edit-open', 'filter-edit-close'],
    components: {FastTable, },
    computed: {
        positionTableData() {
            const result = []

            this.clientPositions.forEach(p => {
                if (!this.canEditRole(p.company_id)) {
                    return  // This user can't edit this company role at all
                }

                const company = this.client.companies.find(c => c.id == p.company_id)
                result.push({
                    id: p.id,
                    isSelected: this.formData.positions.includes(p.id),
                    object: p,
                    cells: [
                        company.name,
                        p.work_location.name,
                        p.terminal_value_no_location,
                    ],
                })
            })

            return [result]
        },
        selectedPositionTableData() {
            let result = this.positionTableData[0].filter(row => {
                return this.formData.positions.includes(row.object.id)
            })

            result = JSON.parse(JSON.stringify(result))
            result.forEach(row => {
                row.isSelected = true
            })

            return [result]
        },
        allPositionCount() {
            return this.positionTableData[0].length
        },
        selectedPositionCount() {
            return this.selectedPositionTableData[0].length
        },
    },
    data() {
        return {
            mode: 'all',
            formData: {
                positions: Array.from(this.modelValue),
            },
            positionSections: [
                {title: 'All', id: 'all'},
            ],
            positionHeaders: [
                {label: 'Company', classes: 'cell-company', defaultSort: true, isFilterable: !this.hideCompanyFilter},
                {label: 'Location', classes: 'cell-org-struct', isSearchable: true, isFilterable: true},
                {label: this.client.position_label_pretty, classes: 'cell-wide', isSearchable: true, isFilterable: true, },
            ],
        }
    },
    methods: {
        canEditRole(companyId) {
            return this.$permissions.hasRolesInCompany(this.client.id, companyId, this.$store.state.security_roles.SETTINGS_ROLES)
        },
        onPositionSelect(rows) {
            const positions = new Set()
            rows.forEach(row => {
                positions.add(row.object.id)
            })

            this.formData.positions = Array.from(positions)

            this.$emit('update:modelValue', this.formData.positions)
        },
        onPositionClick(position) {
            if (this.formData.positions.includes(position.id)) {
                this.formData.positions = this.formData.positions.filter(pId => pId != position.id)
            }
            else {
                this.formData.positions.push(position.id)
            }
            this.$emit('update:modelValue', this.formData.positions)
        },
    },
}

</script>
