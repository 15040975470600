<template>
    <table-page :client="client" :warnings="warnings" :changelogs="changelogs" @warnings_updated="getWarnings">
        <fast-table
            :sections="sections"
            :headers="headers"
            :data="tableData"
            :managers="client.company_managers"
            @click="currentEmployee = $event"
        >

            <template v-slot:table-actions>
                <div class="dropdown">
                    <a href="" class="btn btn-icon-outline text-700 dropdown-toggle" role="button" id="menuYears" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="far fa-fw fa-calendar"></i> <span class="btn-text">{{ currentYear }}</span>
                    </a>
                    <div class="dropdown-menu dropdown-menu-right" aria-labelledby="menuYears">
                        <a class="dropdown-item" @click.prevent="selectYear(year)" v-for="year in years">
                            {{ year }}
                        </a>
                    </div>
                </div>
            </template>

        </fast-table>

        <employee-sidebar
            :client="client"
            :employee-id="currentEmployee.id"
            v-if="currentEmployee"
            @close="currentEmployee = null"
            tab="misc" section="tax-documents"
            @updated="refresh"
            :key="currentEmployee.id"/>
    </table-page>
</template>

<script>
import FastTable from '@/components/FastTable'
import EmployeeSidebar from '@/components/EmployeeSidebar'
import TablePage from '@/components/TablePage'

export default {
    props: ['client'],
    components: {FastTable, EmployeeSidebar, TablePage,  },
    computed: {
        active() {
            return this.employees.filter((employee) => {
                if (employee.employment_status != 'Active') {
                    return false
                }
                return true
            })
        },
        inactive() {
            return this.employees.filter((employee) => {
                if (employee.employment_status != 'Active') {
                    return true
                }
                return false
            })
        },
        tableData() {
            const tableData = []
            const subsets = [
                this.active,
                this.inactive,
            ]

            subsets.forEach(employees => {
                const sectionData = []

                employees.forEach((employee, idx) => {
                    const row = {
                        id: idx,
                        isActive: this.currentEmployee && employee.id == this.currentEmployee.id,
                        object: employee,
                        cells: [
                            employee.full_name_sortable,
                            employee.employee_number,
                            employee.warning_count,
                            employee.company.name,
                            employee.email,
                            employee.ssn_last_4 ? `XXX-XX-${employee.ssn_last_4}` : '---',
                            employee.last_pay_date,
                        ],
                    }

                    sectionData.push(row)
                })

                tableData.push(sectionData)
            })

            return tableData
        },
    },
    data() {
        return {
            currentYear: null, // tax year, not the standard date range filter
            years: [],

            employees: [],
            dataLoaded: false,
            refreshing: false,
            currentEmployee: null,

            headers: [
                {label: 'Employee', classes: '', isSearchable: true, defaultSort: true},
                {label: 'Employee Num', classes: 'cw-7h cell-right', isSearchable: true, sortDataType: 'numeric'},
                {label: 'Warnings', classes: 'cw-warning', isSearchable: false, type: 'warning', isFilterable: true},
                {label: 'Company', classes: 'cell-company', isSearchable: true, isFilterable: true},
                {label: 'Email', classes: 'cell-email', isSearchable: true},
                {label: 'SSN', classes: 'cw-ssn', isSearchable: true},
                {label: 'Last Paid', classes: 'cw-date', isSearchable: true, type: 'date'},
            ],
            sections: [
                {title: 'Active Employees', defaultIsClosed: false, id: 1},
                {title: 'Inactive Employees', defaultIsClosed: true, id: 2},
            ],
            warnings: null,
            changelogs: null,
        }
    },
    mounted() {
        this.$store.dispatch('SET_PAGE_TITLE', 'Employee Tax Documents')
        this.getData()
        this.getWarnings()
        this.getChangelogs()
    },
    methods: {
        selectYear(year) {
            this.currentYear = year
            this.getData()
        },
        getData(silent) {
            if (!silent) {
                this.dataLoaded = false
                this.$store.dispatch('START_LOADING')
            }

            this.refreshing = true

            let url = `/clients/${this.client.id}/employee-w2s`
            if (this.currentYear) {
                url = `${url}?year=${encodeURIComponent(this.currentYear)}`
            }

            this.$api.get(url).then(resp => {
                this.years = resp['years']
                this.currentYear = resp['year_filter']
                this.employees = resp['employees']
                this.dataLoaded = true
                this.refreshing = false
                if (!silent) {
                    this.$store.dispatch('STOP_LOADING')
                }
            }).catch((errors) => {
                if (errors.__status__ == 403) {
                    this.$store.dispatch('STOP_LOADING')
                    this.$bus.$emit('no-access')
                    return
                }

                if (!silent) {
                    this.$store.dispatch('STOP_LOADING')
                }
                this.refreshing = false
                this.$bus.showError(errors.__all__)
            })
        },
        refresh() {
            if (this.refreshing) {return}
            this.getData(true)
            this.getWarnings()
            this.getChangelogs()
        },
        getWarnings() {
            this.warnings = []  // NB: This tile does not have a cohesive warnings table
        },
        getChangelogs() {
            this.changelogs = []  // NB: This tile does not have a cohesive history table
        },
    },
}

</script>
