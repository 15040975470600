<template>
<div>
    <a href class="btn btn-icon-outline btn-collapse-sm text-700 btn-toggle" title="" ref="trigger" @click.prevent="open">
        <i class="far fa-fw fa-calendar mr-1"></i>
        <span class="btn-text" v-if="dateFilterMode == 'lastN'">{{ defaultRange }} {{ defaultRange == 1 ? 'day' : 'days' }}</span>
        <span class="btn-text" v-if="dateFilterMode == 'custom'">{{ $filters.date(dateFilterStart) }} - {{ $filters.date(dateFilterEnd) }}</span>
    </a>
</div>
</template>

<script>
import moment from 'moment'
import Modal from '@/components/Modal'
import ButtonRow from '@/components/ButtonRow'

export default {
    props: {
        'defaultRange': {
            default: 30,
        },
    },
    components: {Modal, ButtonRow, },
    computed: {
        applyEnabled() {
            return Boolean(this.formData.dateFilterStart && this.formData.dateFilterEnd)
        }
    },
    data() {
        return {
            dateFilterMode: 'lastN',
            dateFilterStart: this.makeTodayMoment().subtract(this.defaultRange, 'day').local(),
            dateFilterEnd: this.makeTodayMoment().local(),
            lastNDateRangeInputId: `random-id-${Math.random()}`,
            customDateRangeInputId: `random-id-${Math.random()}`,

            formData: {
                dateFilterMode: 'lastN',
                dateFilterStart: this.makeTodayMoment().subtract(this.defaultRange, 'day').local(),
                dateFilterEnd: this.makeTodayMoment().local(),
            },
        }
    },
    watch: {
        'formData.dateFilterStart'() {
            let s, e;
            s = e = null;

            if (this.formData.dateFilterStart) {
                s = moment.isMoment(this.formData.dateFilterStart) ? this.formData.dateFilterStart : moment(this.formData.dateFilterStart, 'MM/DD/YYYY')
            }

            if (this.formData.dateFilterEnd) {
                e = moment.isMoment(this.formData.dateFilterEnd) ? this.formData.dateFilterEnd : moment(this.formData.dateFilterEnd, 'MM/DD/YYYY')
            }

            if ((s & e) && s > e) {
                this.formData.dateFilterEnd = moment(s)
            }
        },
        'formData.dateFilterEnd'() {
            let s, e;
            s = e = null;

            if (this.formData.dateFilterStart) {
                s = moment.isMoment(this.formData.dateFilterStart) ? this.formData.dateFilterStart : moment(this.formData.dateFilterStart, 'MM/DD/YYYY')
            }

            if (this.formData.dateFilterEnd) {
                e = moment.isMoment(this.formData.dateFilterEnd) ? this.formData.dateFilterEnd : moment(this.formData.dateFilterEnd, 'MM/DD/YYYY')
            }

            if ((s && e) && s > e) {
                this.formData.dateFilterStart = moment(e)
            }
        },
    },
    methods: {
        open($evt) {
            $evt.stopPropagation()

            this.$refs.modal.open()
        },
        closeDateFilterPopover() {
            this.reset()
        },
        applyDateFilter() {

            this.dateFilterMode = this.formData.dateFilterMode
            this.dateFilterStart = this.formData.dateFilterStart
            this.dateFilterEnd = this.formData.dateFilterEnd

            const range = {start: null, end: null}

            if (this.dateFilterMode == 'lastN') {
                const today = this.makeTodayMoment()

                range.end = today
                range.start = moment(today).subtract(this.defaultRange, 'day')
            }
            else {
                range.end = moment(this.dateFilterEnd, 'MM/DD/YYYY').utc()
                range.start = moment(this.dateFilterStart, 'MM/DD/YYYY').utc()
            }

            range.start = range.start.set({
                'hour': 0,
                'minute': 0,
                'second': 0,
                'millisecond': 0,
            }).format()

            range.end = range.end.set({
                'hour': 23,
                'minute': 59,
                'second': 59,
                'millisecond': 999,
            }).format()

            this.$emit('change', range)
            this.$nextTick(() => {
                this.closeDateFilterPopover()
            })
        },
        makeTodayMoment() {
            return moment().set({
                'hour': 0,
                'minute': 0,
                'second': 0,
                'millisecond': 0,
            }).utc()
        },
        reset() {
            this.formData.dateFilterMode = this.dateFilterMode
            this.formData.dateFilterStart = this.dateFilterStart
            this.formData.dateFilterEnd = this.dateFilterEnd
        },
    },
}
</script>
