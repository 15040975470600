import numeral from 'numeral'
import moment from 'moment'
import bus from '@/bus'
import api from '@/api'
import store from '@/store'
import hasher from '@/hasher'

import * as Sentry from "@sentry/vue"
export const PMDateFilters = {

    // disable filters; TODO KB Vue3 need to be replaced
    install(app, options) {
        // Helper function to turn moment, Date, or String into moment object
        const toMoment = (value) => {
            let date_moment = null

            if (moment.isMoment(value)) {
                date_moment = value
            }
            else if (value instanceof Date) {
                date_moment = moment(value)
            }
            else if (value instanceof String || typeof(value) == 'string') {
                // for now at least, try to guess the date format -
                // it'll either be YYYY-MM-DD or MM/DD/YYYY
                let inputformat = 'MM/DD/YYYY'
                if (value && value.indexOf('-') >= 0) {
                    inputformat = 'YYYY-MM-DD'
                    if (value && value.indexOf('T') >= 0) {
                        inputformat = 'YYYY-MM-DD\THH\:mm\:s\.SSS\Z'
                    }
                }

                date_moment = value ? moment(value, inputformat) : null
            }
            return date_moment
        }

        const filters = {
            fromNow: (value) => {
                // set custom relative date thresholds according to our date & time style guide
                moment.relativeTimeThreshold('w', 4); // enables weeks
                moment.relativeTimeThreshold('m', 60);
                moment.relativeTimeThreshold('h', 24);
                moment.relativeTimeThreshold('d', 7);
                moment.relativeTimeThreshold('M', 12);
                return value ? moment(value).fromNow() : ''
            },

            fromNowGranular: (value) => {
                if (!value) {
                    return ''
                }

                const diff = moment().diff(moment(value), 'seconds')
                if (diff == 1) {
                    return '1 second ago'
                }
                return `${diff} seconds ago`
            },

            // Default date format when using date filter in Vue
            // e.g. Wed 08/11/2021
            date: (value, format='ddd MM/DD/Y') => {
                let date_moment = toMoment(value)

                if (date_moment) {
                    return date_moment.format(format)
                }

                return ''
            },

            // Default date format when using dateshort filter in Vue
            // Same as date but abbreviates year to 2 digits so as to be appropriate for use in tables etc.
            // e.g. Wed 08/11/21
            dateshort: (value, format='ddd MM/DD/YY') => {
                let date_moment = toMoment(value)

                if (date_moment) {
                    return date_moment.format(format)
                }

                return ''
            },

            // Default date format when using datelong filter in Vue
            // Used when the date is written out in long text
            // e.g. Wednesday, August 11th, 2021
            datelong: (value, format='dddd, MMMM Do, YYYY') => {
                let date_moment = toMoment(value)

                if (date_moment) {
                    return date_moment.format(format)
                }

                return ''
            },

            // Default date format when using datetime filter in Vue
            // e.g. Wed 08/11/2021 9:18am
            datetime: (value, format='ddd MM/DD/Y h:mm a') => {
                let date_moment = toMoment(value)

                if (date_moment) {
                    return date_moment.format(format)
                }

                return ''
            },

            // Default date format when using datetimeshort filter in Vue
            // Same as date but abbreviates year to 2 digits so as to be appropriate for use in tables etc.
            // e.g. Wed 08/11/21 9:18am
            datetimeshort: (value, format='ddd MM/DD/YY h:mm a') => {
                let date_moment = toMoment(value)

                if (date_moment) {
                    return date_moment.format(format)
                }

                return ''
            },

            // Default date format when using datetimetodayaware filter in Vue
            // This is similar to datetime but displays 'Today' in place of weekday for today's date
            // Use in places where date is referring to an action performed, activity log, or deadline
            // e.g. Today 08/11/2021 9:18am
            datetimetodayaware: (value, format='ddd MM/DD/Y h:mm a') => {
                let date_moment = toMoment(value)

                if (date_moment) {
                    if (date_moment.isSame(moment(), 'day')) {
                        // replace any number of d characters at the start of the format string with [Today]
                        format = format.replace(/^(d+)/, '[Today]')
                    }
                    return date_moment.format(format)
                }

                return ''
            },

            // duration between two timestamps in minutes
            durationmins: (dt_from, dt_to) => {
                // NB: only works on strings (for now at least)
                if ((dt_from instanceof String || typeof(dt_from) == 'string') && (dt_to instanceof String || typeof(dt_to) == 'string')) {
                    let inputformat = 'YYYY-MM-DD\THH\:mm\:s\.SSS\Z'
                    let moment_from = moment(dt_from, inputformat)
                    let moment_to = moment(dt_to, inputformat)
                    let mins = moment_to.diff(moment_from, 'minutes');
                    if (mins > 1) {
                        return mins + ' mins';
                    } else {
                        return mins + ' min';
                    }
                }

                return ''
            },

            formatCurrency: (value) => {
                return numeral(value).format("0,0.00");
            },

            formatPercentage: (p) => {
                p = '' + p
                p = p.replace(/[0]*$/, '')
                p = p.replace(/[.]*$/, '')  // Make "0." become "0"
                return p
            },
        }

        app.config.globalProperties.$filters = filters
    }
}

export const PMBus = {
    install(app, options) {
        app.config.globalProperties.$bus = bus
    }
}

export const PMAPI = {
    install(app, options) {
        app.config.globalProperties.$api = api
    }
}

export const PMPermissions = {
    install(app, options) {
        app.config.globalProperties.$permissions = {
            getSecurityProfile(clientId) {
                if (!store.state.user) {
                    return null
                }

                return store.state.user.security_profiles.find(sp => sp.client == clientId)
            },
            getCompanyIdsByRoles(clientId, roles) {
                if (!store.state.user) {
                    return []
                }

                if (store.state.user.is_superuser) {
                    return this.client.companies.map(c => c.id)
                }

                const companySecurityProfiles = this.getSecurityProfile(clientId).company_security_profiles
                return companySecurityProfiles.filter(csp => roles.indexOf(csp.role) >= 0).map(csp => csp.company)
            },
            hasRoles(clientId, roles) {
                if (!store.state.user) {
                    return false
                }

                if (store.state.user.is_superuser) {
                    return true
                }

                const companySecurityProfiles = store.state.user.security_profiles.find(sp => sp.client == clientId).company_security_profiles
                if (companySecurityProfiles.filter(csp => roles.indexOf(csp.role) >= 0).length) {
                    return true
                }

                return false
            },
            hasRolesInCompany(clientId, companyId, roles) {
                if (!store.state.user) {
                    return false
                }

                if (store.state.user.is_superuser) {
                    return true
                }

                if (import.meta.env.VITE_SENTRY_DSN) {
                    Sentry.addBreadcrumb({
                        category: 'permissions',
                        message: `Checking hasRolesInCompany for ${store.state.user.email} with clientId = ${clientId}, ${companyId}, ${roles}.`,
                        level: 'info',
                    })
                }

                const companySecurityProfiles = store.state.user.security_profiles.find(sp => sp.client == clientId).company_security_profiles
                const csp = companySecurityProfiles.find(c => c.company == companyId)
                if (csp && csp.is_active && roles.indexOf(csp.role) >= 0) {
                    return true
                }

                return false
            },
            isSuperuser() {
                if (!store.state.user) {
                    return false
                }

                return store.state.user.is_superuser
            },
            hasAccess(clientId, companyId, workLocationIds, positionIds) {
                if (!store.state.user) {
                    return false
                }

                if (store.state.user.is_superuser) {
                    return true
                }

                else if (this.hasRolesInCompany(clientId, companyId, store.state.security_roles.COMPANY_LEVEL_ROLES)) {
                    return true
                }

                else if (workLocationIds.length && this.hasRolesInCompany(clientId, companyId, store.state.security_roles.LOCATION_LEVEL_ROLES)) {
                    const companySecurityProfiles = store.state.user.security_profiles.find(sp => sp.client == clientId).company_security_profiles
                    const csp = companySecurityProfiles.find(c => c.company == companyId)
                    const userWorkLocationIds = csp.work_location_security_profiles.map(wlsp => wlsp.work_location)
                    if (workLocationIds.every(wId => userWorkLocationIds.includes(wId))) {
                        return true
                    }
                }

                else if (positionIds.length && this.hasRolesInCompany(clientId, companyId, store.state.security_roles.POSITION_LEVEL_ROLES)) {
                    const companySecurityProfiles = store.state.user.security_profiles.find(sp => sp.client == clientId).company_security_profiles
                    const csp = companySecurityProfiles.find(c => c.company == companyId)
                    const userPositionIds = csp.security_profile_positions.map(spp => spp.position)

                    if (positionIds.every(pId => userPositionIds.includes(pId))) {
                        return true
                    }
                }

                return false
            },
        }
    }
}


export const PMConstants = {
    install(app, options) {
        app.config.globalProperties.$delta = {
            POS_APP_URL: import.meta.env.VITE_POS_APP_URL || 'https://pos.getmypaystub.com'
        }
    }
}


export const IDHasher = {
    install(app, options) {
        app.config.globalProperties.$hasher = hasher
    }
}

