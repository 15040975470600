<template>
    <modal @close="$emit('close')" ref="modal" :fade="false" :v-center="true" :autoshow="true">
        <template v-slot:title>
            Select a Date Range
        </template>

        <slot>
            <form class="mui-form" id="date-filter-form">
                <p>
                    Show {{ recordLabel || "records" }}
                    <template v-if="recordLabel && sectionVerb">
                        <strong>{{ sectionVerb }}</strong> within:
                    </template>
                    <template v-else>
                        from:
                    </template>
                </p>

                <div class="form-check py-hf">
                    <label class="form-check-label pl-qt">
                        <input type="radio" class="form-check-input" name="dateRangeOptions" :checked="formData.dateFilterMode == 'last30'" @click="formData.dateFilterMode = 'last30'" ref="firstInput">
                        Last 30 days
                    </label>
                </div>

                <div class="form-check py-hf">
                    <label class="form-check-label pl-qt">
                        <input type="radio" class="form-check-input" name="dateRangeOptions" :checked="formData.dateFilterMode == 'last90'" @click="formData.dateFilterMode = 'last90'">
                        Last 90 days
                    </label>
                </div>

                <div class="form-check py-hf">
                    <label class="form-check-label pl-qt">
                        <input type="radio" class="form-check-input" name="dateRangeOptions" :checked="formData.dateFilterMode == 'last12'" @click="formData.dateFilterMode = 'last12'">
                        Last 12 months
                    </label>
                </div>

                <div class="form-check py-hf">
                    <label class="form-check-label pl-qt">
                        <input type="radio" class="form-check-input" name="dateRangeOptions" :checked="formData.dateFilterMode == 'last24'" @click="formData.dateFilterMode = 'last24'">
                        Last 24 months
                    </label>
                </div>

                <div class="form-check py-hf">
                    <label class="form-check-label pl-qt">
                        <input type="radio" class="form-check-input" name="dateRangeOptions" :checked="formData.dateFilterMode == 'custom'" @click="formData.dateFilterMode = 'custom'">
                        Custom date range<span v-show="formData.dateFilterMode == 'custom'">:</span>
                    </label>
                </div>
                <div class="h-5r">
                    <div class="pt-1 pl-rs-4" v-show="formData.dateFilterMode == 'custom'">
                        <div class="form-row">
                            <div class="col-6">
                                <div class="mui-textfield pt-0 mb-0">
                                    <form-input type="date" v-model="formData.dateFilterStart" label="Start" />
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="mui-textfield pt-0 mb-0">
                                    <form-input type="date" v-model="formData.dateFilterEnd" label="End" />
                                </div>
                            </div>
                        </div>
                        <div class="field-helper-text text-danger">{{ error }}</div>
                    </div>
                </div>
            </form>
        </slot>

        <template v-slot:footer>
            <button-row stacked>
                <button type="button" class="btn btn-outline-primary" @click.prevent="close">
                    Cancel
                </button>
                <button form="date-filter-form" type="submit" class="btn btn-primary" @click.prevent="applyDateFilter">
                    Apply Date Range
                </button>
            </button-row>
        </template>
    </modal>
</template>

<script>
import $ from 'jquery'
import moment from 'moment'
import Modal from '@/components/Modal'
import ModalMixin from '@/mixins/ModalMixin'
import ButtonRow from '@/components/ButtonRow'
import DateUtils from '@/components/FastTable/date-utils'

export default {
    props: ['mode', 'start', 'end', 'recordLabel', 'sectionId'],
    mixins: [ModalMixin],
    components: {Modal, ButtonRow, },
    computed: {
        applyEnabled() {
            return Boolean(this.formData.dateFilterStart && this.formData.dateFilterEnd)
        },
        sectionVerb() {
            const verbDict = {
                "active": "activated",
                "inactive": "deactivated",
                "hired": "completed",
                "canceled": "canceled",
                "resolved": "resolved",
                "pendingCompletionDocs": "assigned"
            }
            return verbDict[this.sectionId];
        }
    },
    data() {
        const s = moment.isMoment(this.start) ? this.start : moment(this.start)
        const e = moment.isMoment(this.end) ? this.end : moment(this.end)

        return {
            formData: {
                dateFilterMode: this.mode,  // Can be last30, last90, last12, last24, all, or custom
                dateFilterStart: s.local(),
                dateFilterEnd: e.local(),
            },
            error: '',
        }
    },
    mounted() {
        this.$nextTick(() => {
            $(this.$refs.firstInput).focus()
        })
    },
    watch: {
        'formData.dateFilterStart'() {
            let s, e;
            s = e = null;

            if (this.formData.dateFilterStart) {
                s = moment.isMoment(this.formData.dateFilterStart) ? this.formData.dateFilterStart : moment(this.formData.dateFilterStart, 'MM/DD/YYYY')
            }

            if (this.formData.dateFilterEnd) {
                e = moment.isMoment(this.formData.dateFilterEnd) ? this.formData.dateFilterEnd : moment(this.formData.dateFilterEnd, 'MM/DD/YYYY')
            }

            if ((s & e) && s > e) {
                this.formData.dateFilterEnd = moment(s)
            }
        },
        'formData.dateFilterEnd'() {
            let s, e;
            s = e = null;

            if (this.formData.dateFilterStart) {
                s = moment.isMoment(this.formData.dateFilterStart) ? this.formData.dateFilterStart : moment(this.formData.dateFilterStart, 'MM/DD/YYYY')
            }

            if (this.formData.dateFilterEnd) {
                e = moment.isMoment(this.formData.dateFilterEnd) ? this.formData.dateFilterEnd : moment(this.formData.dateFilterEnd, 'MM/DD/YYYY')
            }

            if ((s && e) && s > e) {
                this.formData.dateFilterStart = moment(e)
            }
        },
    },
    methods: {
        close() {
            this.$emit('close')
        },
        applyDateFilter() {
            this.error = ''
            if (this.mode == 'custom' && !this.applyEnabled) {
                this.error = 'Please enter valid dates.'
                return
            }
            const range = DateUtils.makeDateRange(this.formData.dateFilterMode, this.formData.dateFilterStart, this.formData.dateFilterEnd)

            this.$emit('change', range)

            this.$nextTick(() => {
                this.close()
            })
        },
    },
}
</script>
