<template>
    <page>
        <fast-table
            v-show="dataLoaded"
            :sections="sections"
            :headers="headers"
            :data="tableData"
            @click="currentIntegration = $event"
        >

            <template v-slot:table-actions>
                <button type="button" class="btn btn-primary btn-collapse" @click.prevent="openAddIntegrationModal()">
                    <i class="far fa-fw fa-plus"></i> <span class="btn-text">Add Integration</span>
                </button>
            </template>

        </fast-table>

        <pos-add-edit-integration-modal ref="addIntegrationModal" @updated="onUpdate($event)" @close="addIntegrationModalOpen = false" v-if="addIntegrationModalOpen" :client="client" />

        <sidebar :integration="currentIntegration" v-if="currentIntegration" ref="sidebar" @updated="onUpdate($event)" @close="currentIntegration = null" :client="client" />
    </page>
</template>

<script>
import FastTable from '@/components/FastTable'
import Page from '@/components/Page'
import Sidebar from './components/POSIntegrationSidebar'
import POSAddEditIntegrationModal from './components/POSAddEditIntegrationModal'

export default {
    components: {FastTable, Sidebar, Page, PosAddEditIntegrationModal: POSAddEditIntegrationModal, },
    props: ['client', ],
    computed: {
        activeIntegrations() {
            return this.integrations.filter(t => t.is_active)
        },
        inactiveIntegrations() {
            return this.integrations.filter(t => !t.is_active)
        },
        tableData() {
            const tableData = []

            const subsets = [
                this.activeIntegrations,
                this.inactiveIntegrations,
            ]

            subsets.forEach(integrations => {
                const sectionData = []

                integrations.forEach((o, idx) => {
                    const row = {
                        id: idx,
                        isActive: this.currentIntegration && o.id == this.currentIntegration.id,
                        object: o,
                        cells: [
                            o.get_pos_display,
                            o.label,
                            o.ref_code,
                            o.apply_to_stores_default,
                        ],
                    }

                    sectionData.push(row)
                })
                tableData.push(sectionData)
            })

            return tableData
        },
    },
    data() {
        return {
            integrations: [],
            dataLoaded: false,
            currentIntegration: null,
            addIntegrationModalOpen: false,

            headers: [
                {label: 'POS', classes: '', isSearchable: true, isFilterable: true},
                {label: 'Label', classes: '', isSearchable: true},
                {label: 'Reference Code', classes: '', isSearchable: true},
                {label: 'Default', classes: '', type: "checkmark",  isSearchable: false},
            ],
            sections: [
                {title: 'Active', defaultIsClosed: false, id: 1},
                {title: 'Inactive', defaultIsClosed: true, id: 2},
            ],
        }
    },
    mounted() {
        this.$store.dispatch('SET_PAGE_TITLE', `Client ${this.client.client_code} - ${this.client.name} / POS Integrations`)
        this.getData()
    },
    methods: {
        openAddIntegrationModal() {
            this.addIntegrationModalOpen = true
            this.$nextTick(() => {
                this.$refs.addIntegrationModal.open()
            })
        },
        getData() {
            this.dataLoaded = false
            this.$store.dispatch('START_LOADING')
            this.$api.get(`/admin/clients/${this.client.id}/pos-integrations`).then((resp) => {
                this.integrations = resp
                this.dataLoaded = true
                this.$store.dispatch('STOP_LOADING')
            }).catch((errors) => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__[0])
            })
        },
        onUpdate(integration) {
            const existing = this.integrations.find(s => {return s.id == integration.id})
            if (existing) {
                Object.assign(existing, integration)
            } else {
                this.integrations.push(integration)
            }
        },
    },
}

</script>
