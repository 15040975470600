<template>
    <div>
        <div class="flex-page-content flex-container">
            <div class="flex-body body-scroll">
                <div v-if="taskRespsLoaded" class="container-fluid">
                    <div v-if="taskRespsToDisplay.length" class="container-centered-s py-4">

                        <h5>{{ company.name }}</h5>
                        <div v-show="!activeUniformTaskResponse && !activeCustomFormTaskResponse">
                            <h3 v-if="redirected">Welcome.</h3>
                            <p>Your manager has assigned you {{ numTasksToComplete }} {{ numTasksToComplete > 1 ? "documents" : "document" }} to complete:</p>

                            <div v-for="resp in taskRespsToDisplay" :key="resp.id">

                            <div class="card doc-item mb-2" v-if="resp.employee_hr_document">
                                <div class="card-body">
                                    <div class="todo-item" :class="{'todo-item-completed': resp.employee_hr_document.signed_on}">
                                        <div class="todo-item-content">
                                            <h6 class="doc-title mb-2">{{ resp.employee_hr_document.hr_document.name }}<span v-if="resp.task.block_ee_until_completion" class="text-primary"> *</span></h6>

                                            <div class="doc-action-primary mb-1">

                                                <a v-if="!resp.employee_hr_document.signed_on" href class="btn btn-outline-primary" @click.prevent="showHRDocumentTaskResp(resp)">
                                                    <i class="far fa-fw fa-external-link mr-1"></i>Sign Document
                                                </a>

                                                <a href v-if="resp.employee_hr_document.signed_on" class="btn btn-outline-success" @click.prevent="showHRDocumentTaskResp(resp)">
                                                    <i class="far fa-fw fa-check mr-1"></i>View Signed Document
                                                </a>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="card doc-item mb-2" v-if="resp.employee_hr_custom_form">
                                <div class="card-body">
                                    <div class="todo-item" :class="{'todo-item-completed': resp.employee_hr_custom_form.filled_on}">
                                        <div class="todo-item-content">
                                            <h6 class="doc-title mb-2">{{ resp.employee_hr_custom_form.hr_custom_form.name }}<span v-if="resp.task.block_ee_until_completion" class="text-primary"> *</span></h6>

                                            <div class="doc-action-primary mb-1">

                                                <a v-if="!resp.employee_hr_custom_form.filled_on" href class="btn btn-outline-primary" @click.prevent="setActiveCustomFormTaskResponse(resp)">
                                                    <i class="far fa-fw fa-external-link mr-1"></i>Fill Out Document
                                                </a>

                                                <a href v-if="resp.employee_hr_custom_form.filled_on" class="btn btn-outline-success" @click.prevent="setActiveCustomFormTaskResponse(resp)">
                                                    <i class="far fa-fw fa-check mr-1"></i>View Filled Document
                                                </a>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="card doc-item mb-2" v-if="resp.employee_uniform">
                                <div class="card-body">
                                    <div class="todo-item" :class="{'todo-item-completed': resp.employee_uniform.filled_on}">
                                        <div class="todo-item-content">
                                            <h6 class="doc-title mb-2">{{ resp.employee_uniform.uniform.name }}<span v-if="resp.task.block_ee_until_completion" class="text-primary"> *</span></h6>

                                            <div class="doc-action-primary mb-1">

                                                <a v-if="!resp.employee_uniform.filled_on" href class="btn btn-outline-primary" @click.prevent="setActiveUniformTaskResponse(resp)">
                                                    <i class="far fa-fw fa-external-link mr-1"></i>Fill Out Document
                                                </a>

                                                <a href v-if="resp.employee_uniform.filled_on" class="btn btn-outline-success" @click.prevent="setActiveUniformTaskResponse(resp)">
                                                    <i class="far fa-fw fa-check mr-1"></i>View Filled Document
                                                </a>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            </div>

                            <form-errors :errors="formErrors"/>

                            <p v-if="blockingTasksExist" class="text-small text-muted mb-0">
                                Documents marked with an asterisk (<span class="text-primary">*</span>) must be completed before proceeding.
                            </p>

                            <form @submit.prevent="onContinue">
                                <button-row stacked>
                                    <button class="btn btn-primary" :to="{name: 'employee-company-home', params: this.$route.params.hashedCompanyId}">Continue to Dashboard</button>
                                </button-row>
                            </form>
                        </div>

                        <div v-if="activeUniformTaskResponse" class="mb-4">
                            <uniform-survey
                                :uniform="activeUniformTaskResponse.employee_uniform"
                                ref="activeUniformSurvey"
                                :hidesubmit="true"
                            />

                            <form-errors :errors="formErrors" />
                            <button-row stacked>
                                <button class="btn btn-outline-primary" @click.prevent="setActiveUniformTaskResponse(null)">Back</button>
                                <button class="btn btn-primary" @click.prevent="onUniformSubmit">Save</button>
                            </button-row>
                        </div>

                        <div v-if="activeCustomFormTaskResponse" class="mb-4">
                            <custom-form-survey
                                :custom-form="activeCustomFormTaskResponse.employee_hr_custom_form"
                                ref="activeCustomFormSurvey"
                            />

                            <form-errors :errors="formErrors" />
                            <button-row stacked>
                                <button class="btn btn-outline-primary" @click.prevent="setActiveCustomFormTaskResponse(null)">Back</button>
                                <button class="btn btn-primary" @click.prevent="onCustomFormSubmit">Save</button>
                            </button-row>
                        </div>

                    </div>

                    <div v-else class="container-centered-s py-4 text-center">
                        <p>You have no tasks to complete.</p>
                        <router-link class="btn btn-outline-primary" :to="{name: 'employee-company-home', params: this.$route.params.hashedCompanyId}">Back</router-link>
                    </div>

                </div>
            </div>
        </div>

        <pdf-modal v-if="currentPDF" :url="currentPDF.url" :title="currentPDF.title" :has-accept="true" @close="currentPDF = null" ref="pdfModal">

            <template v-slot:footer v-if="currentPDF.doc && !currentPDF.doc.signed_on">
                <div class="form-row">
                    <div class="col-12">
                        <p>I have read and agree with this document.</p>
                    </div>
                </div>

                <button-row>
                    <button type="button" class="btn btn-block btn-outline-primary mb-1" @click.prevent="currentPDF = null">
                        Close
                    </button>
                    <button type="button" class="btn btn-block btn-primary mb-1" @click.prevent="signHRDocument(currentPDF)">
                        Sign Document
                    </button>
                </button-row>
            </template>

        </pdf-modal>

    </div>
</template>


<script>
import FormMixin from '@/mixins/Form'
import PdfModal from '@/components/PdfModal'
import ButtonRow from '@/components/ButtonRow'
import UniformSurvey from '@/components/UniformSurvey'
import CustomFormSurvey from '@/components/CustomFormSurvey'
import moment from 'moment'

export default {
    mixins: [FormMixin, ],
    components: {PdfModal, ButtonRow, UniformSurvey, CustomFormSurvey, },
    props: ['company'],
    mounted() {
        this.loadTaskResponses()
    },
    computed: {
        API_BASE() {
            return this.$api.API_BASE
        },
        companyId() {
            return this.$hasher.decode(this.$route.params.hashedCompanyId)
        },
        blockingTasksExist() {
            return !!(this.taskResps.some(t => (t.task.block_ee_until_completion)))
        },
        allBlockingTasksCompleted() {
            return !(this.taskResps.some(t => (t.task.block_ee_until_completion && !t.responded_on)))
        },
        taskRespsToDisplay() {
            return this.taskResps
        },
        numTasksToComplete() {
            return this.taskRespsToDisplay.length
        },
        redirected() {
            return !!(this.$route.query.redirected)
        }
    },
    data() {
        return {
            activeUniformTaskResponse: null,
            activeCustomFormTaskResponse: null,
            currentPDF: null,
            taskResps: [],
            taskRespsLoaded: false
        }
    },
    methods: {
        sortTaskResps(resps) {
            resps.sort((a, b) => {
                if (a.task.block_ee_until_completion && !b.task.block_ee_until_completion) {
                    return -1;
                } else if (!a.task.block_ee_until_completion && b.task.block_ee_until_completion) {
                    return 1;
                } else {
                    return (a.task.document_name > b.task.document_name ? 1 : -1)
                }
            })

            return resps
        },
        validate() {
            if (!this.allBlockingTasksCompleted) {
                this.formErrors.push("Complete all documents marked with an asterisk (*) to continue.")
                return false
            }
            return true
        },
        onContinue() {
            this.formErrors = []

            if (!this.validate()) {
                return
            }

            this.$router.push({name: 'employee-company-home', params: {hashedCompanyId: this.$route.params.hashedCompanyId}})
        },
        showHRDocumentTaskResp(resp) {
            if (!resp) {
                this.currentPDF = null
                return
            }

            let doc = resp.employee_hr_document

            let url = ''
            if (doc.signed_on) {
                url = `${this.API_BASE}/me/ess/companies/${this.companyId}/hr-documents/${doc.id}/signed`
            }
            else {
                url = `${this.API_BASE}/me/ess/companies/${this.companyId}/hr-documents/${doc.id}/file`
            }

            this.currentPDF = {
                title: doc.hr_document.name,
                url: {
                    url: url,
                    withCredentials: true,
                },
                doc: doc,
                respId: resp.id
            }
        },
        setActiveUniformTaskResponse(resp) {
            this.formErrors = []
            if (!resp) {
                this.activeUniformTaskResponse = null
                return
            }
            this.activeUniformTaskResponse = resp
        },
        setActiveCustomFormTaskResponse(resp) {
            this.formErrors = []
            if (!resp) {
                this.activeCustomFormTaskResponse = null
                return
            }
            this.activeCustomFormTaskResponse = resp
        },
        onUniformSubmit() {
            if (!this.$refs.activeUniformSurvey.validate()) {
                return
            }
            let data = this.$refs.activeUniformSurvey.getFormData()
            this.$store.dispatch('START_LOADING')
            this.$api.post(`/me/ess/companies/${this.companyId}/task-responses/${this.activeUniformTaskResponse.id}`, data).then(resp => {
                this.loadTaskResponses()
                this.setActiveUniformTaskResponse(null)
            }).catch((errors) => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__)
            })
        },
        onCustomFormSubmit() {
            if (!this.$refs.activeCustomFormSurvey.validate()) {
                return
            }
            let data = this.$refs.activeCustomFormSurvey.getFormData()
            this.$store.dispatch('START_LOADING')
            this.$api.post(`/me/ess/companies/${this.companyId}/task-responses/${this.activeCustomFormTaskResponse.id}`, data).then(resp => {
                this.loadTaskResponses()
                this.setActiveCustomFormTaskResponse(null)
            }).catch((errors) => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__)
            })
        },
        signHRDocument(currentPDF) {
            const signatureData = {
                'date': moment().format('MM/DD/YYYY'),
                'datetime': moment().format('MM/DD/YYYY HH:mm:ss Z'),
            }

            this.$store.dispatch('START_LOADING')
            this.$api.post(`/me/ess/companies/${this.companyId}/task-responses/${currentPDF.respId}`, signatureData).then(resp => {
                this.loadTaskResponses()
                this.$refs.pdfModal.close()
            }).catch((errors) => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__)
            })
        },
        loadTaskResponses() {
            this.$store.dispatch('START_LOADING')
            this.$api.get(`/me/ess/companies/${this.companyId}/task-responses?show_recent=1`).then(taskResps => {
                this.taskResps = this.sortTaskResps(taskResps)
                this.taskRespsLoaded = true
                this.$store.dispatch('STOP_LOADING')
            }).catch((errors) => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__)
            })
        }
    },
}
</script>
