<template>
    <table-page
        :client="client"
        :warnings="warnings"
        :changelogs="changelogs"
        @changelog_date_filter_change="onChangelogDateRangeChange"
        @warnings_date_filter_change="onWarningsDateRangeChange"
        @warnings_updated="getWarnings"
        @updated="refresh"
    >
        <fast-table
            :sections="sections"
            :headers="headers"
            :data="tableData"
            :managers="client.company_managers"
            :use-date-filters="true"
            @date_filter_change="onDateFilter"
            @click="currentEmployee = $event"
        >

            <template v-slot:table-actions>
            </template>

        </fast-table>

        <employee-sidebar
            :client="client"
            :employee-id="currentEmployee.id"
            v-if="currentEmployee"
            @close="currentEmployee = null"
            tab="main"
            @updated="refresh"
            :key="currentEmployee.id"/>
    </table-page>
</template>

<script>
import FastTable from '@/components/FastTable'
import EmployeeSidebar from '@/components/EmployeeSidebar'
import TablePageMixin from '@/mixins/TablePageMixin'

export default {
    props: ['client'],
    components: {FastTable, EmployeeSidebar, },
    mixins: [TablePageMixin, ],
    computed: {
        active() {
            return this.employees.filter((employee) => {
                if (employee.employment_status != 'Active') {
                    return false
                }
                return true
            })
        },
        inactive() {
            return this.employees.filter((employee) => {
                if (employee.employment_status != 'Active') {
                    return true
                }
                return false
            })
        },
        tableData() {
            const tableData = []
            const subsets = [
                this.active,
                this.inactive,
            ]

            subsets.forEach(employees => {
                const sectionData = []

                employees.forEach((employee, idx) => {
                    let warnings = ''
                    if (this.warnings) {
                        const employeeWarnings = this.mainWarnings.filter(w => w.employee_id == employee.id)
                        warnings = employeeWarnings.filter(w => !w.is_resolved).length
                        if (warnings < 1) {
                            if (employeeWarnings.filter(w => w.warning_type == 'i9-completed-late').length) {
                                warnings = 'scarlet-letter'
                            }
                        }
                    }

                    const row = {
                        id: idx,
                        isActive: this.currentEmployee && employee.id == this.currentEmployee.id,
                        object: employee,
                        cells: [
                            employee.full_name_sortable,
                            employee.employee_number,
                            warnings,
                            employee.company.name,
                            employee.email,
                            employee.ssn_last_4 ? `XXX-XX-${employee.ssn_last_4}` : '---',
                            employee.last_pay_date,
                        ],
                    }

                    sectionData.push(row)
                })

                tableData.push(sectionData)
            })

            return tableData
        },
    },
    data() {
        return {
            dateRanges: {},

            employees: [],
            dataLoaded: false,
            refreshing: false,
            currentEmployee: null,

            headers: [
                {label: 'Employee', classes: '', isSearchable: true, defaultSort: true},
                {label: 'Employee Num', classes: 'cw-7h cell-right', isSearchable: true, sortDataType: 'numeric'},
                {label: 'Warnings', classes: 'cw-warning', isSearchable: false, type: 'warning', isFilterable: true},
                {label: 'Company', classes: 'cell-company', isSearchable: true, isFilterable: true},
                {label: 'Email', classes: 'cell-email', isSearchable: true},
                {label: 'SSN', classes: 'cw-ssn', isSearchable: true},
                {label: 'Last Paid', classes: 'cw-date', isSearchable: true, type: 'date'},
            ],
            sections: [
                {title: 'Active Employees', defaultIsClosed: false, id: 'active', hasDateFilter: false},
                {title: 'Inactive Employees', defaultIsClosed: true, id: 'inactive', hasDateFilter: true},
            ],
            warnings: null,
            changelogs: null,
        }
    },
    mounted() {
        this.$store.dispatch('SET_PAGE_TITLE', 'Employees')
        this.getData()
        this.getWarnings()
        this.getChangelogs()
        this.$bus.$on('client_data_updated', updates => {
            if (updates.indexOf('employees') >= 0) {
                this.getData(true)
                this.getWarnings()
                this.getChangelogs()
            }
        })
    },
    methods: {
        getData(silent) {
            if (!silent) {
                this.dataLoaded = false
                this.$store.dispatch('START_LOADING')
            }

            this.refreshing = true

            this.getMainWarnings()

            let url = `/clients/${this.client.id}/employees`

            const params = []
            this.sections.forEach(s => {
                const range = this.dateRanges[s.id]
                if (range) {
                    params.push(`date-filter.${s.id}.start=${encodeURIComponent(range.start)}`)
                    params.push(`date-filter.${s.id}.end=${encodeURIComponent(range.end)}`)
                }
            })

            url += '?' + params.join('&')

            this.$api.get(url).then(resp => {
                this.employees = resp['employees']
                this.dataLoaded = true
                this.refreshing = false
                if (!silent) {
                    this.$store.dispatch('STOP_LOADING')
                }
            }).catch((errors) => {
                if (errors.__status__ == 403) {
                    this.$store.dispatch('STOP_LOADING')
                    this.$bus.$emit('no-access')
                    return
                }

                if (!silent) {
                    this.$store.dispatch('STOP_LOADING')
                }
                this.refreshing = false
                this.$bus.showError(errors.__all__)
            })
        },
        refresh() {
            if (this.refreshing) {return}
            this.getData(true)
            this.getWarnings()
            this.getChangelogs()
        },
        onDateFilter(dateRanges) {
            this.dateRanges = dateRanges
            this.getData()
        },
        filterRelevantWarnings(w) {
            return w.employee_id
        },
        filterRelevantChangelog(c) {
            return c.employee_id || c.onboarding_application_id
        },
    },
}

</script>
