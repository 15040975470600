<template>
    <page>
        <fast-table
            v-show="dataLoaded"
            :sections="sections"
            :headers="headers"
            :tableClasses="tableClasses"
            :data="tableData"
            @click="currentQuestion = $event"
        >
            <template v-slot:table-actions>
                <button type="button" class="btn btn-primary btn-collapse-md" @click.prevent="currentQuestion = null; $refs.addModal.open()">
                    <i class="far fa-fw fa-plus"></i>
                    <span class="btn-text">Add Question</span>
                </button>
            </template>
        </fast-table>

        <sidebar :question="currentQuestion" v-if="currentQuestion" ref="sidebar" @updated="onUpdate" @close="currentQuestion = null" />
        <add-modal ref="addModal" @updated="getQuestions()" />
    </page>
</template>

<script>
import FastTable from '@/components/FastTable'
import Page from '@/components/Page'
import Sidebar from './components/QuestionSidebar'
import AddModal from './components/QuestionModal'

export default {
    components: {FastTable, Page, Sidebar, AddModal, },
    computed: {
        tableData() {
            const tableData = []

            this.questions.forEach((q, idx) => {
                const row = {
                    id: idx,
                    isActive: this.currentQuestion && q.id == this.currentQuestion.id,
                    object: q,
                    cells: [
                        q.name,
                        q.state ? q.state.name : '',
                        q.slug,
                        q.text,
                    ],
                }

                tableData.push(row)
            })

            return [tableData]
        },
    },
    data() {
        return {
            questions: [],
            dataLoaded: false,
            currentQuestion: null,

            tableClasses: 'table-anchor--longtext',
            headers: [
                {label: 'Name', classes: '', isSearchable: true},
                {label: 'State', classes: 'cw-5', isSearchable: true, defaultSort: true},
                {label: 'slug', classes: 'cw-30', isSearchable: true},
                {label: 'Text', classes: 'cw-30', isSearchable: true},
            ],
            sections: [
                {title: 'Active', defaultIsClosed: false, id: 1},
            ],
        }
    },
    mounted() {
        this.$store.dispatch('SET_PAGE_TITLE', 'Questions')
        this.getQuestions()
    },
    methods: {
        getQuestions() {
            this.dataLoaded = false
            this.$store.dispatch('START_LOADING')
            this.$api.get(`/admin/pdf-forms/questions`).then(resp => {
                this.questions = resp
                this.dataLoaded = true
                this.$store.dispatch('STOP_LOADING')
            }).catch((errors) => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__[0])
            })
        },
        onUpdate(question) {
            const existing = this.questions.find(q => {return q.id == question.id})
            if (existing) {
                Object.assign(existing, question)
            }
        },
    },
}

</script>
