<template>
    <modal size="xl" height-max @close="$emit('close')" ref="modal">
        <template v-slot:title>{{ title }}</template>

        <template v-slot:headerbuttons>
            <button class="btn btn-outline-primary btn-collapse-xl" :disabled="scale >= 4" @click.prevent="scale = scale * 1.25">
                <i class="far fa-fw fa-search-plus"></i>
                <span class="sr-only">Zoom In</span>
            </button>
            <button class="btn btn-outline-primary btn-collapse-xl ml-1" :disabled="scale <= 0.5" @click.prevent="scale = scale / 1.25">
                <i class="far fa-fw fa-search-minus"></i>
                <span class="sr-only">Zoom Out</span>
            </button>
            <button class="btn btn-outline-primary btn-collapse-rs ml-1" @click.prevent="print()">
                <i class="far fa-fw fa-file-pdf"></i>
                <span class="btn-text ml-hf">PDF</span>
            </button>
        </template>

        <template v-slot:subheading>
        </template>

        <slot>
            <div class="pdf-viewer-wrapper">
                <VuePDF v-for="page in src.pages" :pdf="src.pdf" :page="page" :scale="2" @loaded="isLoaded=true" :style="{ '--zoom': zoom }"/>
            </div>
            <div class="loader loader-modal" v-show="!isLoaded">
                <div class="spinner">
                    <div class="lds-grid">
                        <div></div><div></div><div></div>
                        <div></div><div></div><div></div>
                        <div></div><div></div><div></div>
                    </div>
                </div>
            </div>
        </slot>

        <template v-slot:footer>
            <slot name="footer">
            </slot>
        </template>
    </modal>
</template>

<script>
import ModalMixin from '@/mixins/ModalMixin'
import { dragscroll } from 'vue-dragscroll'
import { VuePDF, usePDF } from '@tato30/vue-pdf'

export default {
    mixins: [ModalMixin],
    directives: {dragscroll},
    components: {
        VuePDF,
    },
    props: ['url', 'title'],
    data() {
        return {
            isLoaded: false,
            src: this.getDocument(),
            scale: 1,
        }
    },
    computed: {
        zoom() {
            return `${this.scale * 100}%`
        }
    },
    watch: {
        url() {
            this.src = this.getDocument()
        },
    },
    methods: {
        getDocument() {
            if (!this.url) {
                return null
            }
            this.isLoaded = false
            const { pdf, pages } = usePDF(this.url)
            const src = {
                pdf: pdf,
                pages: pages
            }
            return src
        },
        print() {
            let pdf_url = this.url.url
            if (! this.$store.state.isInApp) {
                window.open(pdf_url)
                return
            }

            this.$store.dispatch('START_LOADING')
            this.$api.get(pdf_url, false, false).then(resp => {
                this.$store.dispatch('STOP_LOADING')
                // use this rather than ArrayBufferToString because ArrayBufferToString
                // sometimes results in: 'RangeError: Maximum call stack size exceeded'
                function _arrayBufferToBase64( buffer ) {
                    var binary = '';
                    var bytes = new Uint8Array( buffer );
                    var len = bytes.byteLength;
                    for (var i = 0; i < len; i++) {
                        binary += String.fromCharCode( bytes[ i ] );
                    }
                    return window.btoa( binary );
                }

                if (typeof global !== 'undefined' && global.webkit && global.webkit.messageHandlers && global.webkit.messageHandlers.cordova_iab) {
                    global.webkit.messageHandlers.cordova_iab.postMessage(JSON.stringify({
                        command: 'print',
                        content: btoa(_arrayBufferToBase64(resp))
                    }))
                } else {
                    console.error("In app, but global.webkit.messageHandlers not found");
                }
            }).catch(errors => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__[0])
            })
        }
    },
}
</script>

<style lang="scss" scoped>
.pdf-viewer-wrapper {
    margin-left: auto;
    margin-right: auto;
    :deep(canvas) {
        box-shadow: 0 1px 5px rgba(0, 0, 0, 0.25);
        margin-left: auto;
        margin-right: auto;
        margin-top: 1em;
        margin-bottom: 1em;
        width: var(--zoom) !important;
        height: auto !important;
    }
}
</style>
