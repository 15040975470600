<template>
    <page>
        <fast-table
            v-show="dataLoaded"
            ref="table"
            :sections="sections"
            :headers="headers"
            :data="tableData"
            :tableClasses="tableClasses"
            @click="onClick"
        >

            <template v-slot:table-actions>
                <div class="dropdown">
                    <a href="" class="btn btn-icon-outline text-700 dropdown-toggle" role="button" id="menuYears" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="far fa-fw fa-calendar"></i> <span class="btn-text">{{ currentYear }}</span>
                    </a>
                    <div class="dropdown-menu" aria-labelledby="menuYears">
                        <a class="dropdown-item" @click.prevent="selectYear(year)" v-for="year in years">
                            {{ year }}
                        </a>
                    </div>
                </div>

            </template>

        </fast-table>

        <w2-review-sidebar :w2reviewdetail="currentW2Detail" v-if="currentW2Detail" @close="currentW2Detail = null" />

    </page>

</template>

<script>
import FastTable from '@/components/FastTable'
import W2ReviewSidebar from './components/W2ReviewSidebar'
import Page from '@/components/Page'

export default {
    props: ['client', ],
    components: {FastTable, W2ReviewSidebar, Page},
    computed: {
        unresolvedW2s() {
            return this.w2s.filter(t => !t.flag_resolved_on)
        },
        resolvedW2s() {
            return this.w2s.filter(t => t.flag_resolved_on)
        },
        tableData() {
            const tableData = []

            const subsets = [
                this.unresolvedW2s,
                this.resolvedW2s,
            ]

            subsets.forEach(integrations => {
                const sectionData = []

                integrations.forEach((p, idx) => {
                    const company = this.companies[p.company]
                    const row = {
                        id: idx,
                        isActive: this.currentW2Detail && p.id == this.currentW2Detail.id,
                        object: p,
                        cells: [
                            p.employee.full_name_sortable,
                            p.employee.employee_number,

                            company.company_code,
                            company.name,
                            company.client.client_code,
                            company.client.name,

                            p.employee.last_pay_date,
                            p.employee.ssn_last_4 ? `XXX-XX-${p.employee.ssn_last_4}` : '---',
                            p.flag_issues.length,
                        ],
                    }

                    sectionData.push(row)
                })
                tableData.push(sectionData)
            })

            return tableData
        },
    },
    data() {
        return {
            currentYear: null, // check year, not the standard date range filter
            years: [],

            companies: [],
            w2s: [],
            dataLoaded: false,
            currentW2Detail: null,

            tableClasses: 'table-anchor--shorttext',
            headers: [
                {label: 'Employee', classes: '', isSearchable: true, defaultSort: true},
                {label: 'Employee Num', classes: 'cw-7h cell-right', isSearchable: true, sortDataType: 'numeric'},

                {label: 'Company Code', classes: '', isSearchable: true},
                {label: 'Company', classes: 'cell-company', isSearchable: true},
                {label: 'Client Code', classes: '', isSearchable: true},
                {label: 'Client', classes: 'cell-company', isSearchable: true},

                {label: 'Last Pay Date', classes: 'cell-medium', isSearchable: false, type: 'date'},
                {label: 'SSN', classes: 'cell-medium', isSearchable: true},

                {label: 'Flags', classes: 'cell-narrow', isSearchable: true},
            ],
            sections: [
                {title: 'Unresolved', defaultIsClosed: false, id: 1},
                {title: 'Resolved', defaultIsClosed: true, id: 2},
            ],
        }
    },
    mounted() {
        this.$store.dispatch('SET_PAGE_TITLE', 'W2s To Review')
        this.getCompanies().then(() => {
            this.getW2sToReview()
        })
    },
    methods: {
        selectYear(year) {
            this.currentYear = year
            this.getW2sToReview()
        },

        getCompanies() {
            return this.$api.get(`/admin/companies`).then(resp => {
                this.companies = {}
                resp.forEach(c => {
                    this.companies[c.id] = c
                })
            }).catch((errors) => {
                this.$bus.showError(errors.__all__[0])
            })
        },
        getW2sToReview() {
            this.dataLoaded = false

            let url = `/admin/w2-review`
            if (this.currentYear) {
                url = `${url}?year=${encodeURIComponent(this.currentYear)}`
            }

            this.$store.dispatch('START_LOADING')
            this.$api.get(url).then(resp => {
                this.years = resp['years']
                this.currentYear = resp['year_filter']
                this.w2s = resp['w2s']
                this.dataLoaded = true
                this.$store.dispatch('STOP_LOADING')
            }).catch((errors) => {
                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__[0])
            })
        },
        onClick(w2detail) {
            this.currentW2Detail = w2detail
        }

    },
}

</script>
