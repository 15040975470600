<template>
<div>
    <div class="nav-tabs-container pt-1">
        <div class="nav-tabs-scroll-helper">
            <ul class="nav nav-tabs">

                <li class="nav-item">
                    <a class="nav-link" :class="{'active': mode == 'all'}" href id="tabLinkPositions1" role="tab" aria-controls="tabContentPositions1" :aria-selected="mode == 'all'" @click.prevent="mode = 'all'; $bus.$emit('resize')">
                        All Positions ({{ allPositionCount }})
                    </a>
                </li>

                <li class="nav-item">
                    <a class="nav-link" :class="{'active': mode == 'selected'}" href id="tabLinkPositions2" role="tab" aria-controls="tabContentPositions1" :aria-selected="mode == 'selected'" @click.prevent="mode = 'selected'; $bus.$emit('resize')">
                        Selected ({{ selectedPositionCount }})
                    </a>
                </li>

            </ul>
        </div>
    </div>

    <div class="flex-body flex-container tab-content position-selector">
        <div class="flex-body flex-container tab-pane" :class="{'show active': mode == 'all'}" id="tabContentPositions1" role="tabpanel" aria-labelledby="tabLinkPositions1">
            <fast-table
                :sections="positionSections"
                :headers="positionHeaders"
                :data="positionTableData"
                @click="onPositionClick"
                @select="onPositionSelect"
                @filter-edit-open="$emit('filter-edit-open')"
                @filter-edit-close="$emit('filter-edit-close')"
                :use-overlay-filter-editor="true"
                :has-row-select="true"
                :show-search="true"
                :show-filter="false"
                :show-more-actions="false"
            />

        </div>

        <div class="flex-body flex-container tab-pane" :class="{'show active': mode == 'selected'}" id="tabContentPositions2" role="tabpanel" aria-labelledby="tabLinkPositions2">
            <fast-table
                :sections="positionSections"
                :headers="positionHeaders"
                :data="selectedPositionTableData"
                @click="onPositionClick"
                @select="onPositionSelect"
                @filter-edit-open="$emit('filter-edit-open')"
                @filter-edit-close="$emit('filter-edit-close')"
                :use-overlay-filter-editor="true"
                :has-row-select="true"
                :show-search="true"
                :show-filter="false"
                :show-more-actions="false"
            />

        </div>
        <!-- Add filters -->

    </div>
</div>
</template>

<script>
import FastTable from '@/components/FastTable'

export default {
    props: ['allPositions', 'companies', 'modelValue', ],
    emits: ['update:modelValue', 'filter-edit-open', 'filter-edit-close'],
    components: {FastTable, },
    data() {
        let data = {
            mode: 'all',
            formData: {
                positions: Array.from(this.modelValue),
            },
            positionSections: this.makePositionSections(),
            positionHeaders: [
                    {label: 'Position', classes: 'cell-company', defaultSort: true, isFilterable: true, isSearchable: true, },
            ],
        }
        return data
    },
    computed: {
        positionTableData() {
            let result = []
            let resultsByCompany = {}
            this.allPositions.forEach(p => {
                // if (!this.canEditRole(c.id)) {
                //     return  // This user can't edit this company role at all
                // }
                if (!(p.company.name in resultsByCompany)) {
                    resultsByCompany[p.company.name] = []
                }
                resultsByCompany[p.company.name].push({
                    id: p.id,
                    isSelected: this.formData.positions.includes(p.id),
                    object: p,
                    cells: [
                        p.terminal_value,
                    ],
                })
            })
            let sortedKeys = Object.keys(resultsByCompany).sort()
            sortedKeys.forEach(k => {
                result.push(resultsByCompany[k])
            })
            return result
        },
        // positionTableData() {
        //     let result = []
        //     this.allPositions.forEach(p => {
        //         // if (!this.canEditRole(c.id)) {
        //         //     return  // This user can't edit this company role at all
        //         // }
        //         result.push({
        //             id: p.id,
        //             isSelected: this.formData.positions.includes(p.id),
        //             object: p,
        //             cells: [
        //                 p.terminal_value,
        //             ],
        //         })
        //     })
        //     return [result]
        // },
        selectedPositionTableData() {
            let result = []
            this.positionTableData.forEach(companyPositionTableData => {
                let companyResult = companyPositionTableData.filter(row => {
                    return this.formData.positions.includes(row.object.id)
                })
                companyResult = JSON.parse(JSON.stringify(companyResult))
                companyResult.forEach(row => {
                    row.isSelected = true
                })
                result.push(companyResult)
            })

            return result
        },
        // selectedPositionTableData() {
        //     let result = this.positionTableData[0].filter(row => {
        //         return this.formData.positions.includes(row.object.id)
        //     })

        //     result = JSON.parse(JSON.stringify(result))
        //     result.forEach(row => {
        //         row.isSelected = true
        //     })

        //     return [result] },
        allPositionCount() {
            return this.allPositions.length
            // return this.positionTableData[0].length
        },
        selectedPositionCount() {
            return this.formData.positions.length
            // return this.selectedPositionTableData[0].length
        },
        // positionsByCompany() {
        //     return this.getPositionsByCompany(this.allPositions)
        // },
    },
    watch: {
        allPositions() {
            this.$bus.$emit('resize')
        }
    },
    mounted() {
        this.$bus.$emit('resize')
    },
    methods: {
        // canEditRole(companyId) {
        //     return this.$permissions.hasRolesInCompany(this.client.id, companyId, this.$store.state.security_roles.SETTINGS_ROLES)
        // },
        makePositionSections() {
            let result = []
            let resultsByCompany = {}
            this.allPositions.forEach(p => {
                if (!(p.company.name in resultsByCompany)) {
                    resultsByCompany[p.company.name] = {title: p.company.name, id: p.company.id}
                }
            })
            let sortedKeys = Object.keys(resultsByCompany).sort()
            sortedKeys.forEach(k => {
                result.push(resultsByCompany[k])
            })
            return result
        },
        onPositionSelect(rows) {
            const positions = new Set()
            rows.forEach(row => {
                positions.add(row.object.id)
            })

            this.formData.positions = Array.from(positions)

            this.$emit('update:modelValue', this.formData.positions)
        },
        onPositionClick(position) {
            if (this.formData.positions.includes(position.id)) {
                this.formData.positions = this.formData.positions.filter(pId => pId != position.id)
            }
            else {
                this.formData.positions.push(position.id)
            }

            this.$emit('update:modelValue', this.formData.positions)
        },
        // getPositionsByCompany(positions) {
        //     const mapping = {}
        //     const companies = []
        //     positions.forEach(p => {
        //         if (!mapping[p.company.id]) {
        //             mapping[p.company.id] = []
        //             companies.push(p.company)
        //         }

        //         mapping[p.company.id].push(p)
        //     })

        //     const result = []
        //     companies.forEach(c => {
        //         result.push({
        //             'company': c,
        //             'positions': mapping[c.id]
        //         })
        //     })

        //     return result
        // },
    },
}

</script>

<style>
.position-selector .table-wrapper {
    max-height: 320px;
    min-height: 280px;
}
</style>
