<template>
    <modal size="xl" @close="$emit('close')" ref="modal">
        <template v-slot:title>
          W2 Comparison
          <span v-if="w2detail"> - {{ w2detail.w2.tax_year }}</span>
     </template>

        <template v-slot:subheading>
            <div v-if="w2detail">
               {{ w2detail.w2.company.name }} /
               {{ w2detail.w2.employee.full_name }}
            </div>
        </template>

        <slot>
            <div v-if="w2detail">
              <div class="row">
                  <div class="col-12">
                      <table class="table css-table table-fixed">
                          <tr>
                              <td></td>
                              <td>GetMyPayStub</td>
                              <td>Filed</td>
                              <td></td>
                          </tr>
                          <tr>
                              <td>Box 1</td>
                              <td>{{ w2detail.w2.html_form_data.box_1 }}</td>
                              <td v-if="w2detail.filed_w2">{{ w2detail.filed_w2.get_boxes.box_1 }}</td>
                              <td v-if="w2detail.filed_w2">
                                  <span v-if="w2detail.w2.html_form_data.box_1 == w2detail.filed_w2.get_boxes.box_1">
                                      OK
                                  </span>
                                  <span v-else>
                                      MISMATCH
                                  </span>
                              </td>
                          </tr>
                          <tr>
                              <td>Box 2</td>
                              <td>{{ w2detail.w2.html_form_data.box_2 }}</td>
                              <td v-if="w2detail.filed_w2">{{ w2detail.filed_w2.get_boxes.box_2 }}</td>
                              <td v-if="w2detail.filed_w2">
                                  <span v-if="w2detail.w2.html_form_data.box_2 == w2detail.filed_w2.get_boxes.box_2">
                                      OK
                                  </span>
                                  <span v-else>
                                      MISMATCH
                                  </span>
                              </td>
                          </tr>
                          <tr>
                              <td>Box 3</td>
                              <td>{{ w2detail.w2.html_form_data.box_3 }}</td>
                              <td v-if="w2detail.filed_w2">{{ w2detail.filed_w2.get_boxes.box_3 }}</td>
                              <td v-if="w2detail.filed_w2">
                                  <span v-if="w2detail.w2.html_form_data.box_3 == w2detail.filed_w2.get_boxes.box_3">
                                      OK
                                  </span>
                                  <span v-else>
                                      MISMATCH
                                  </span>
                              </td>
                          </tr>
                          <tr>
                              <td>Box 4</td>
                              <td>{{ w2detail.w2.html_form_data.box_4 }}</td>
                              <td v-if="w2detail.filed_w2">{{ w2detail.filed_w2.get_boxes.box_4 }}</td>
                              <td v-if="w2detail.filed_w2">
                                  <span v-if="w2detail.w2.html_form_data.box_4 == w2detail.filed_w2.get_boxes.box_4">
                                      OK
                                  </span>
                                  <span v-else>
                                      MISMATCH
                                  </span>
                              </td>
                          </tr>
                          <tr>
                              <td>Box 5</td>
                              <td>{{ w2detail.w2.html_form_data.box_5 }}</td>
                              <td v-if="w2detail.filed_w2">{{ w2detail.filed_w2.get_boxes.box_5 }}</td>
                              <td v-if="w2detail.filed_w2">
                                  <span v-if="w2detail.w2.html_form_data.box_5 == w2detail.filed_w2.get_boxes.box_5">
                                      OK
                                  </span>
                                  <span v-else>
                                      MISMATCH
                                  </span>
                              </td>
                          </tr>
                          <tr>
                              <td>Box 6</td>
                              <td>{{ w2detail.w2.html_form_data.box_6 }}</td>
                              <td v-if="w2detail.filed_w2">{{ w2detail.filed_w2.get_boxes.box_6 }}</td>
                              <td v-if="w2detail.filed_w2">
                                  <span v-if="w2detail.w2.html_form_data.box_6 == w2detail.filed_w2.get_boxes.box_6">
                                      OK
                                  </span>
                                  <span v-else>
                                      MISMATCH
                                  </span>
                              </td>
                          </tr>
                          <tr>
                              <td>Box 7</td>
                              <td>{{ w2detail.w2.html_form_data.box_7 }}</td>
                              <td v-if="w2detail.filed_w2">{{ w2detail.filed_w2.get_boxes.box_7 }}</td>
                              <td v-if="w2detail.filed_w2">
                                  <span v-if="w2detail.w2.html_form_data.box_7 == w2detail.filed_w2.get_boxes.box_7">
                                      OK
                                  </span>
                                  <span v-else>
                                      MISMATCH
                                  </span>
                              </td>
                          </tr>
                          <tr>
                              <td>Box 8</td>
                              <td>{{ w2detail.w2.html_form_data.box_8 }}</td>
                              <td v-if="w2detail.filed_w2">{{ w2detail.filed_w2.get_boxes.box_8 }}</td>
                              <td v-if="w2detail.filed_w2">
                                  <span v-if="w2detail.w2.html_form_data.box_8 == w2detail.filed_w2.get_boxes.box_8">
                                      OK
                                  </span>
                                  <span v-else>
                                      MISMATCH
                                  </span>
                              </td>
                          </tr>

                          <!-- Box 12 - but only show ones where there's one or the other -->
                          <tr v-for="box_12_comp in box12_comparisons">
                              <td>
                                Box 12
                                {{ box_12_comp.code }}
                              </td>
                              <td>{{ box_12_comp.calculated_value }}</td>
                              <td>{{ box_12_comp.filed_value }}</td>
                              <td v-if="w2detail.filed_w2">
                                  <span v-if="box_12_comp.matches">
                                      OK
                                  </span>
                                  <span v-else>
                                      MISMATCH
                                  </span>
                              </td>
                          </tr>


                          <tr>
                              <td>Box 13 A</td>
                              <td>
                                <span v-if="w2detail.w2.html_form_data.box_13_a">Y</span>
                                <span v-else>N</span>
                              </td>
                              <td v-if="w2detail.filed_w2">
                                <span v-if="w2detail.filed_w2.get_box_13_data.box_13_a">Y</span>
                                <span v-else>N</span>
                              </td>
                              <td v-if="w2detail.filed_w2">
                                  <span v-if="w2detail.w2.html_form_data.box_13_a == w2detail.filed_w2.get_box_13_data.box_13_a">
                                      OK
                                  </span>
                                  <span v-else>
                                      MISMATCH
                                  </span>
                              </td>
                          </tr>

                          <tr>
                              <td>Box 13 B</td>
                              <td>
                                <span v-if="w2detail.w2.html_form_data.box_13_b">Y</span>
                                <span v-else>N</span>
                              </td>
                              <td v-if="w2detail.filed_w2">
                                <span v-if="w2detail.filed_w2.get_box_13_data.box_13_b">Y</span>
                                <span v-else>N</span>
                              </td>
                              <td v-if="w2detail.filed_w2">
                                  <span v-if="w2detail.w2.html_form_data.box_13_b == w2detail.filed_w2.get_box_13_data.box_13_b">
                                      OK
                                  </span>
                                  <span v-else>
                                      MISMATCH
                                  </span>
                              </td>
                          </tr>

                          <tr>
                              <td>Box 13 C</td>
                              <td>
                                <span v-if="w2detail.w2.html_form_data.box_13_c">Y</span>
                                <span v-else>N</span>
                              </td>
                              <td v-if="w2detail.filed_w2">
                                <span v-if="w2detail.filed_w2.get_box_13_data.box_13_c">Y</span>
                                <span v-else>N</span>
                              </td>
                              <td v-if="w2detail.filed_w2">
                                  <span v-if="w2detail.w2.html_form_data.box_13_c == w2detail.filed_w2.get_box_13_data.box_13_c">
                                      OK
                                  </span>
                                  <span v-else>
                                      MISMATCH
                                  </span>
                              </td>
                          </tr>

                          <tr>
                              <td># States</td>
                              <td>{{ w2detail.w2.get_totals.num_states }}</td>
                              <td v-if="w2detail.filed_w2">{{ w2detail.filed_w2.get_state_local_totals.num_states }}</td>
                              <td v-if="w2detail.filed_w2">
                                  <span v-if="w2detail.w2.get_totals.num_states == w2detail.filed_w2.get_state_local_totals.num_states">
                                      OK
                                  </span>
                                  <span v-else>
                                      MISMATCH
                                  </span>
                              </td>
                          </tr>

                          <tr>
                              <td>Sum State Wages</td>
                              <td>{{ w2detail.w2.get_totals.total_state_wages }}</td>
                              <td v-if="w2detail.filed_w2">{{ w2detail.filed_w2.get_state_local_totals.total_state_wages }}</td>
                              <td v-if="w2detail.filed_w2">
                                  <span v-if="w2detail.w2.get_totals.total_state_wages == w2detail.filed_w2.get_state_local_totals.total_state_wages">
                                      OK
                                  </span>
                                  <span v-else>
                                      MISMATCH
                                  </span>
                              </td>
                          </tr>

                          <tr>
                              <td>Sum State WH</td>
                              <td>{{ w2detail.w2.get_totals.total_state_wh }}</td>
                              <td v-if="w2detail.filed_w2">{{ w2detail.filed_w2.get_state_local_totals.total_state_wh }}</td>
                              <td v-if="w2detail.filed_w2">
                                  <span v-if="w2detail.w2.get_totals.total_state_wh == w2detail.filed_w2.get_state_local_totals.total_state_wh">
                                      OK
                                  </span>
                                  <span v-else>
                                      MISMATCH
                                  </span>
                              </td>
                          </tr>

                          <tr>
                              <td># Locals</td>
                              <td>{{ w2detail.w2.get_totals.num_locals }}</td>
                              <td v-if="w2detail.filed_w2">{{ w2detail.filed_w2.get_state_local_totals.num_locals }}</td>
                              <td v-if="w2detail.filed_w2">
                                  <span v-if="w2detail.w2.get_totals.num_locals == w2detail.filed_w2.get_state_local_totals.num_locals">
                                      OK
                                  </span>
                                  <span v-else>
                                      MISMATCH
                                  </span>
                              </td>
                          </tr>

                          <tr>
                              <td>Sum Local Wages</td>
                              <td>{{ w2detail.w2.get_totals.total_local_wages }}</td>
                              <td v-if="w2detail.filed_w2">{{ w2detail.filed_w2.get_state_local_totals.total_local_wages }}</td>
                              <td v-if="w2detail.filed_w2">
                                  <span v-if="w2detail.w2.get_totals.total_local_wages == w2detail.filed_w2.get_state_local_totals.total_local_wages">
                                      OK
                                  </span>
                                  <span v-else>
                                      MISMATCH
                                  </span>
                              </td>
                          </tr>

                          <tr>
                              <td>Sum Local WH</td>
                              <td>{{ w2detail.w2.get_totals.total_local_wh }}</td>
                              <td v-if="w2detail.filed_w2">{{ w2detail.filed_w2.get_state_local_totals.total_local_wh }}</td>
                              <td v-if="w2detail.filed_w2">
                                  <span v-if="w2detail.w2.get_totals.total_local_wh == w2detail.filed_w2.get_state_local_totals.total_local_wh">
                                      OK
                                  </span>
                                  <span v-else>
                                      MISMATCH
                                  </span>
                              </td>
                          </tr>

                      </table>
                  </div>
              </div>
            </div>
        </slot>

        <template v-slot:footer>
            <slot name="footer">
            </slot>
        </template>
    </modal>
</template>

<script>
import api from '@/api'
import bus from '@/bus'
import ModalMixin from '@/mixins/ModalMixin'

export default {
    mixins: [ModalMixin],
    props: ['w2reviewdetail'],
    data() {
        return {
            w2detail: null,
        }
    },
    mounted() {
        this.w2detail = this.w2reviewdetail
    },
    computed: {
        box12_comparisons() {
            let box_12_data = {}

            for (const [key, val] of Object.entries(this.w2detail.w2.box_12_data)) {
                if (! box_12_data.hasOwnProperty(key)) {
                    box_12_data[key] = {
                        "code": key,
                        "calculated_value": "",
                        "filed_value": "",
                    }
                }
                box_12_data[key]["calculated_value"] = val
            }

            if (this.w2detail.filed_w2) {
              for (const [key, val] of Object.entries(this.w2detail.filed_w2.get_box_12_data)) {
                  if (! box_12_data.hasOwnProperty(key)) {
                      box_12_data[key] = {
                          "code": key,
                          "calculated_value": "",
                          "filed_value": "",
                      }
                  }
                  box_12_data[key]["filed_value"] = val
              }
            }

            Object.keys(box_12_data).forEach((key) => {
                box_12_data[key]["matches"] = (box_12_data[key]["calculated_value"] == box_12_data[key]["filed_value"])
            });

            return box_12_data
        }
    },
    watch: {
        w2reviewdetail() {
            this.w2detail = this.w2reviewdetail
        }
    },
}
</script>
