<template>
    <table-page :client="client" :warnings="warnings" :changelogs="changelogs" @warnings_updated="getWarnings">
        <fast-table
            :sections="sections"
            :headers="headers"
            :data="tableData"
            @click="onClick($event)"
        >

            <template v-slot:table-actions>
                <div class="dropdown">
                    <a href="" class="btn btn-icon-outline text-700 dropdown-toggle" role="button" id="menuYears" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="far fa-fw fa-calendar"></i> <span class="btn-text">{{ currentYear }}</span>
                    </a>
                    <div class="dropdown-menu dropdown-menu-right" aria-labelledby="menuYears">
                        <a class="dropdown-item" @click.prevent="selectYear(year)" v-for="year in years">
                            {{ year }}
                        </a>
                    </div>
                </div>
            </template>

        </fast-table>

    </table-page>
</template>

<script>
import FastTable from '@/components/FastTable'
import TablePage from '@/components/TablePage'

export default {
    props: ['client'],
    components: {FastTable, TablePage, },
    computed: {
        tableData() {
            const tableData = []

            this.tax_forms.forEach((d, idx) => {
                const row = {
                    id: idx,
                    isActive: this.currentTaxForm && d.id == this.currentTaxForm.id,
                    object: d,
                    cells: [
                        d.document_title,
                        d.company ? d.company.company_code : '',
                        d.company ? d.company.name : d.ein_formatted,
                        d.tax_year,
                        d.get_quarter_display,
                        d.num_employees,
                    ],
                }

                tableData.push(row)
            })

            return [tableData]
        },
    },
    data() {
        return {
            currentYear: null, // tax year, not the standard date range filter
            years: [],

            tax_forms: [],
            dataLoaded: false,
            currentTaxForm: null,

            headers: [
                {label: 'Document', classes: '', isSearchable: true, defaultSort: true},
                {label: 'Company Code', classes: 'cw-5h cell-right', isSearchable: true, isFilterable: true},
                {label: 'Company', classes: 'cell-company', isSearchable: true, isFilterable: true},
                {label: 'Tax Year', classes: 'cw-4h cell-center', isSearchable: true, isFilterable: false},
                {label: 'Quarter', classes: 'cw-4 cell-center', isSearchable: true, isFilterable: true},
                {label: '# Employees', classes: 'cw-5h cell-right pr-2', isSearchable: false},
            ],
            sections: [
                {title: 'Active Employees', defaultIsClosed: false, id: 1},
            ],
            warnings: null,
            changelogs: null,
        }
    },
    mounted() {
        this.$store.dispatch('SET_PAGE_TITLE', 'Company Tax Documents')
        this.getData()
        this.getWarnings()
        this.getChangelogs()
    },
    methods: {
        selectYear(year) {
            this.currentYear = year
            this.getData()
        },
        onClick($event) {
            this.currentTaxForm = $event
            window.open(this.currentTaxForm.doc_file_url, '_blank')
        },
        getData() {
            this.dataLoaded = false
            this.$store.dispatch('START_LOADING')

            let url = `/clients/${this.client.id}/tax-forms`
            if (this.currentYear) {
                url = `${url}?year=${encodeURIComponent(this.currentYear)}`
            }

            this.$api.get(url).then(resp => {
                this.years = resp['years']
                this.currentYear = resp['year_filter']
                this.tax_forms = resp['tax_forms']
                this.dataLoaded = true
                this.$store.dispatch('STOP_LOADING')
            }).catch((errors) => {
                if (errors.__status__ == 403) {
                    this.$store.dispatch('STOP_LOADING')
                    this.$bus.$emit('no-access')
                    return
                }

                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__[0])
            })
        },
        getWarnings() {
            this.warnings = []  // NB: This tile does not have a warnings table defined yet
        },
        getChangelogs() {
            this.changelogs = []  // NB: This tile does not have a cohesive history table
        },
    },
}

</script>
